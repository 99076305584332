<template>
  <div class="overlay"
    v-if="isVisible"
  >
    <div class="overlay-inner">
      <div class="my-modal">
        <svg class="my-modal-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
          @click="$emit( 'update:isVisible', false )"
        >
          <rect y="28.2843" width="40" height="2" transform="rotate(-45 0 28.2843)" fill="white"/>
          <rect x="1.41406" y="0.00012207" width="40" height="2" transform="rotate(45 1.41406 0.00012207)" fill="white"/>
        </svg>

        <div class="flat-block flat-block--reverse">
          <div class="flat-block__first flat-block__first--large">
            <slot name="content"></slot>
          </div>
          <div class="flat-block__section">
            <slot name="preview"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>

</style>