<template>
	<div class="house-selection__bottom">
		<div class="map">
      <div
        v-if="facadeIsLoaded && facade"
      >
        <img class="house-selection-img" alt=""
          :src="facade.image"
        >
  
        <svg class="map-svg"
          v-bind:viewBox="`0 0 ${ imgWidth } ${ imgHeight }`"
        >
          <g
            v-for="area of facade.areas"
            :key="area.id"
            style="transform: scaleY(-1) translateY(-100%); -webkit-transform: scaleY(-1) translateY(-100%);"
          >
            <polygon class="map-polygon"
              :style="{
                'pointer-events': isFloorExist( area ) ? 'none' : 'inherit'
              }"
              :points="area.coordinates.map( coordinate => `${ coordinate[0] },${ coordinate[1] }` ).join(' ')"
              @click="selectArea( area )"
            ></polygon>
            <foreignObject width="103" height="46"
              :x="getXByArea( area )"
              :y="getYByArea( area ) - 23"
              style="pointer-events: none; overflow: visible;"
            >
              <div style="position: fixed; display: inline-flex; height: 46px; padding: 0 14px; background-color: #FFF; border-radius: 6px; align-items: center; gap: 10px;"
                :style="labelStyle"
              >
                <div style="width: 23px; height: 23px; border-radius: 50%; background: #E4943D; font-size: 13px; line-height: 13px; color: #FFF; display: flex; align-items: center; justify-content: center;">{{ area.floorNumber }}</div>
                <div style="font-size: 16px;line-height: 19px; color: #383C3E;">Этаж</div>
              </div>
            </foreignObject>
          </g>
        </svg>
      </div>
    </div>
	</div>
</template>

<script>
  import profitbaseReguestService from '@/shared/requests/profitbaseReguestService'
  
  import { token } from '@/shared/axios/profitbase/store'

  import { houseId, selectedArea, houseFloorsCount } from '@/modules/house/state'

  import { floorList, propertyList } from '@/modules/floor/store'

  export default {
    setup() {
      return { houseId, selectedArea, houseFloorsCount, floorList, propertyList }
    },

    data() { return {
      facade: {},
      facadeIsLoaded: false,
      imgWidth: 0,
      imgHeight: 0
    } },

    methods: {
      selectArea( area ) {
        this.selectedArea = area
      },

      isFloorExist( area ) {
        return !this.floorList.find( floor => floor.number === area.floorNumber )
      },

      getXByArea( area ) {
        try {
          const min = Math.min( ...area.coordinates.map( el => el[ 0 ] ) )
          const max = Math.max( ...area.coordinates.map( el => el[ 0 ] ) )
          return ( min + ( ( max - min ) / 2 ) )
        } catch( e ) {
          return null
        }
      },
      getYByArea( area ) {
        try {
          const min = Math.min( ...area.coordinates.map( el => el[ 1 ] ) )
          const max = Math.max( ...area.coordinates.map( el => el[ 1 ] ) )
          return ( min + ( ( max - min ) / 2 ) )
        } catch( e ) {
          return null
        }
      }
    },

    computed: {
      labelStyle() {
        if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent) )
          return {
            transform: 'translateY(-100%)',
            '-webkit-transform': 'translateY(-100%)'
          }
        return {
          transform: 'scaleY(-1)',
          '-webkit-transform': 'scaleY(-1)'
        }
      }
    },

    mounted() {
      this.propertyList = []

      profitbaseReguestService.getFacade( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.facade = response.data[ 0 ]
          this.facadeIsLoaded = true

          const img = new Image()
          img.addEventListener( 'load', () => {
            [ this.imgWidth, this.imgHeight ] = [ img.naturalWidth, img.naturalHeight ]
          })
          img.src = this.facade.image
        } )

      profitbaseReguestService.getHouseFloorsCount( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.houseFloorsCount = response.data.data[ 0 ].count
        } )
      
      profitbaseReguestService.getFloorList( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.floorList = response.data
        } )
        
      profitbaseReguestService.getPropertyList( { token: token.value, houseId: this.houseId, full: true } )
        .then( response => {
          this.propertyList.push( ...response.data.data )
        } )
        
      profitbaseReguestService.getPropertyList( { token: token.value, houseId: this.houseId, full: true, offset: 100 } )
        .then( response => {
          this.propertyList.push( ...response.data.data )
        } )
    }
  }
</script>

<style scoped>
  .map {
    position: relative;
  }

  .map-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .map-polygon {
    stroke-width: 1;
    cursor: pointer;
    fill: transparent;
  }

  .map-polygon:hover {
    fill: rgb(228, 148, 61, .7);
  }

  .map-polygon + foreignObject {
    display: none;
  }
  .map-polygon:hover + foreignObject {
    display: block;
  }
</style>