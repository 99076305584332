<template>
  <div class="plugin-select" tabindex="-1"
    @blur="onBlur"
    @click="onClick"
  >
    <div class="plugin-select__wrapper"
      :class="{ 'plugin-select__wrapper_active': isActive }"
    >
      <div class="d-flex align-items-center justify-content-between mn-2">
        <div class="">
          <span class="title">{{ value ? value.text : '&nbsp;' }}</span>
        </div>
        <div class="px-2">
          <svg width="29" height="13" viewBox="0 0 29 13" fill="none" xmlns="http://www.w3.org/2000/svg"
            :class="{ 'plugin-select__icon_active': isActive }"
          >
            <path d="M1 1L14.5 12L28 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

      <ul class="plugin-select__list"
        v-if="isActive"
      >
        <li class="plugin-select__list-item"
          v-for="option of options.filter( option => option !== value )"
          :key="option.id"
          @click="onSelect( option )"
        >
          <span>{{ option.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {},
      options: { type: Array }
    },

    data() { return {
      isActive: false
    } },

    methods: {
      onBlur() { this.isActive = false },

      onClick() { this.isActive = !this.isActive },

      onSelect( option ) {
        this.$emit( 'update:value', option )
      }
    }
  }
</script>