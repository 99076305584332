<template>
	<div class="filter-items__item"
		v-if="plan"
	>
		<div class="item">
			<div class="item__top">
				<div class="item__title">
					<div class="item-title"
						:title="`${ plan.code }`"
					>
						<span>{{ plan.roomsAmount }}-к {{ flat.area.area_total }} м<sup>2</sup></span>
					</div>
				</div>
				<div class="item__photo"
          :style="{ backgroundImage: `url('${ plan.image.big }')` }"
        ></div>
			</div>
			<div class="item__bottom">
				<div class="item__price item-price">{{ new Intl.NumberFormat().format( flat.price.value ) }} ₽</div>
				<div class="item__items">
					<ul class="item-props">
						<li class="item-props__li">
							<div class="item-prop">
								<svg width="20" height="21" class="item-prop__icon" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 0.552612V20.4473H19.8947V0.552612H0ZM18.7245 12.2553H6.43685V13.4255H18.7245V19.2767H7.60709V16.351H6.43689V19.2767H1.17065V13.4255H3.51123V12.2553H1.17065V1.72285H7.02182V7.57402H4.09616V8.74422H15.7989V7.57402H8.19217V1.72285H18.7247L18.7245 12.2553Z" fill="#E4943D"/>
								</svg>
								{{ plan.roomsAmount }}-комн
							</div>
						</li>
						<li class="item-props__li">
							<div class="item-prop">
								<svg width="21" height="21" class="item-prop__icon" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M19.7082 0.552612H6.4452C6.1399 0.552612 5.89258 0.799936 5.89258 1.10524V14.3682C5.89258 14.6735 6.1399 14.9209 6.4452 14.9209H19.7082C20.0135 14.9209 20.2608 14.6735 20.2608 14.3682V1.10524C20.2608 0.799936 20.0135 0.552612 19.7082 0.552612ZM19.1556 13.8156H6.99783V1.65786H19.1556V13.8156Z" fill="#E4943D"/>
									<path d="M1.31064 12.3196C1.09462 12.1035 0.744911 12.1035 0.52919 12.3196C0.313477 12.5356 0.313165 12.8853 0.52919 13.101L2.18707 14.7589C2.29485 14.867 2.43609 14.921 2.57763 14.921C2.71918 14.921 2.86042 14.867 2.96851 14.7592L4.62638 13.1013C4.84241 12.8853 4.84241 12.5356 4.62638 12.3199C4.41036 12.1042 4.06065 12.1039 3.84493 12.3199L3.13027 13.0343V2.43926L3.84466 3.15365C3.95275 3.26174 4.09398 3.31571 4.23553 3.31571C4.37708 3.31571 4.51832 3.26174 4.62641 3.15396C4.84243 2.93793 4.84243 2.58823 4.62641 2.37251L2.96853 0.714631C2.75251 0.498606 2.4028 0.498606 2.18708 0.714631L0.529206 2.37251C0.313181 2.58853 0.313181 2.93824 0.529206 3.15396C0.745231 3.36967 1.09494 3.36998 1.31066 3.15396L2.02504 2.43957V13.0346L1.31064 12.3196Z" fill="#E4943D"/>
									<path d="M18.4416 16.188C18.2256 15.972 17.8759 15.972 17.6602 16.188C17.4444 16.404 17.4441 16.7537 17.6602 16.9695L18.3745 17.6839H7.77954L8.49392 16.9695C8.70995 16.7534 8.70995 16.4037 8.49392 16.188C8.2779 15.9723 7.92819 15.972 7.71247 16.188L6.0546 17.8459C5.83857 18.0619 5.83857 18.4116 6.0546 18.6273L7.71247 20.2852C7.82056 20.3933 7.9618 20.4473 8.10335 20.4473C8.24489 20.4473 8.38614 20.3933 8.49422 20.2855C8.71025 20.0695 8.71025 19.7198 8.49422 19.5041L7.77956 18.7894H18.3746L17.6602 19.5038C17.4442 19.7198 17.4442 20.0695 17.6602 20.2853C17.768 20.3933 17.9092 20.4473 18.0507 20.4473C18.1923 20.4473 18.3335 20.3933 18.4416 20.2856L20.0995 18.6277C20.3155 18.4117 20.3155 18.062 20.0995 17.8462L18.4416 16.188Z" fill="#E4943D"/>
								</svg>
								{{ flat.area.area_total }} м<sup>2</sup>
							</div>
						</li>
						<li class="item-props__li">
							<div class="item-prop">
								<svg width="22" height="19" class="item-prop__icon" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M0.745897 0.420288C0.61958 0.420288 0.49427 0.47226 0.404957 0.561573C0.315644 0.650886 0.263672 0.776196 0.263672 0.902513V18.3608C0.263672 18.4869 0.315644 18.6124 0.404957 18.7017C0.49427 18.791 0.61958 18.843 0.745897 18.843H21.0185C21.3978 18.8396 21.5834 18.6364 21.5794 18.283V14.8996C21.5794 14.7732 21.5274 14.6479 21.4381 14.5586C21.3488 14.4693 21.2233 14.4173 21.0972 14.4173H18.189V12.4427C18.189 12.3164 18.137 12.1909 18.0479 12.1016C17.9586 12.0123 17.8331 11.9603 17.7068 11.9603H14.799V9.51705C14.799 9.39073 14.747 9.26525 14.6577 9.17594C14.5684 9.08663 14.4429 9.03482 14.3166 9.03482H11.4088V6.62977C11.4088 6.50345 11.3568 6.37797 11.2675 6.28866C11.1782 6.19935 11.0527 6.14737 10.9264 6.14754H8.01857V3.72345C8.01857 3.59714 7.96659 3.47166 7.87745 3.38235C7.78814 3.29303 7.66266 3.24106 7.53634 3.24106H4.61858V0.902918C4.61858 0.776601 4.56661 0.65129 4.4773 0.561978C4.38798 0.472665 4.26267 0.420693 4.13636 0.420693L0.745897 0.420288ZM1.22811 1.38489H3.65405V3.64432C3.66129 4.02345 3.87204 4.19419 4.21399 4.20525H7.05386V6.55063C7.07707 6.94388 7.26293 7.10032 7.61293 7.1119H10.4442V9.43795C10.4301 9.82043 10.6533 9.98764 11.0033 9.99922H13.8346V12.3638C13.8432 12.7388 14.0445 12.9134 14.3945 12.9247H17.225V14.8206C17.2373 15.2181 17.4342 15.3701 17.7841 15.3819H20.6153V17.8783H1.22807V1.38502L1.22811 1.38489Z" fill="#E4943D"/>
								</svg>
								{{ flat.floor }} этаж
							</div>
						</li>
					</ul>
				</div>
				<div class="item__btn">
					<div class="item-btn">
						<button class="my-btn" style="width: 100%;"
              @click="showDialog"
            >Подробнее</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import { show, flat as currentFlat } from '@/modules/plugin/components/PluginDialog/state'

	import { token } from '@/shared/axios/profitbase/store'

	import { ref } from 'vue'

	import { presetList } from '../state'

  export default {
    props: {
      flat: {
        type: Object
      }
    },

    setup( props ) {
			const plan = ref( null )

      const showDialog = () => {
        currentFlat.value = props.flat
        show()
      }

      return {
        showDialog, token, plan,
				presetList
      }
    },

		computed: {
			plan() {
				const plan = this.presetList.find( el => el.properties ? el.properties.includes( `${ this.flat.id }` ) : false )
				return plan || undefined
			}
		}
  }
</script>

<style scoped>
	.item-title {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.item__text.item-text {
	}

	.item__photo {
		background-size: contain;
	}
</style>