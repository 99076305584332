<template>
	<div class="main-filter__params">
		<div class="filter-params">
			<div class="filter-params__param">
				<div class="param">
					<div class="param__heading">
						<div class="param-head">Количество комнат</div>
					</div>
					<div class="param__bottom">
            <plugin-radio-group
              clearOnSelectedClick
              :items="items"
              v-model:value="roomsNumber"
            />
					</div>
				</div>
			</div>
			<div class="filter-params__param">
				<div class="param">
					<div class="param__heading">
						<div class="param-head">Стоимость, ₽ </div>
					</div>
					<div class="param__bottom">
						<div class="param-col">
							<div class="param-col__item">
								<div class="param-row">
									<div class="param-row__item">
										<div class="custom-input">
											<div class="custom-input__field">
												<input type="text" class="my-input" disabled
													:value="Math.floor( ( priceRange[ 0 ] / 100000 ) ) / 10"
												>
											</div>
										</div>
									</div>
									<div class="param-row__item">
										<div class="custom-input">
											<div class="custom-input__field">
												<input type="text" class="my-input" disabled
													:value="Math.floor( ( priceRange[ 1 ] / 100000 ) ) / 10"
												>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="param-col__item">
								<plugin-range
									:min="priceRangeMin"
									:max="priceRangeMax"
									v-model:value="priceRange"
								></plugin-range>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="filter-params__param">
				<div class="param">
					<div class="param__heading">
						<div class="param-head">Площадь, м<sup>2</sup></div>
					</div>
					<div class="param__bottom">
						<div class="param-col">
							<div class="param-col__item">
								<div class="param-row">
									<div class="param-row__item">
										<div class="custom-input">
											<div class="custom-input__field">
												<input type="text" id="sqr-from" class="my-input" disabled
													:value="areaRange[ 0 ]"
												>
											</div>
										</div>
									</div>
									<div class="param-row__item">
										<div class="custom-input">
											<div class="custom-input__field">
												<input type="text" id="sqr-to" class="my-input" disabled
													:value="areaRange[ 1 ]"
												>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="param-col__item">
								<plugin-range
									:min="areaRangeMin"
									:max="areaRangeMax"
									v-model:value="areaRange"
								></plugin-range>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="filter-params__param">
				<div class="param">
					<div class="param__heading">
						<div class="param-head">Этаж</div>
					</div>
					<div class="param__bottom">
						<div class="param-col">
							<div class="param-col__item">
								<div class="param-row">
									<div class="param-row__item">
										<div class="custom-input">
											<div class="custom-input__field">
												<input type="text" id="floor-from" class="my-input" disabled
													:value="floorRange[ 0 ]"
												>
											</div>
										</div>
									</div>
									<div class="param-row__item">
										<div class="custom-input">
											<div class="custom-input__field">
												<input type="text" id="floor-to" class="my-input" disabled
													:value="floorRange[ 1 ]"
												>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="param-col__item">
								<plugin-range
									:min="floorRangeMin"
									:max="floorRangeMax"
									v-model:value="floorRange"
								></plugin-range>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="filter-params__param" style="flex-grow: 1;">
				<div class="param">
					<div class="param__heading">
						<div class="param-head">&nbsp;</div>
					</div>
					<div class="param__bottom">
						<div class="filter-btn">
							<button class="my-btn" style="white-space: nowrap;"
								@click="onFetchButtonClick"
							>Показать квартиры</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import PluginRadioGroup from '../../plugin/components/PluginRadioGroup'
	import PluginRange from '@/modules/plugin/components/PluginRange'

  import {
		roomsNumber as roomsNumberFilter, priceMin, priceMax, areaMin, areaMax, floorMin, floorMax,
		fetchFlatList, flatList, currentPage, isLoading,
		priceRangeMin, priceRangeMax,
		areaRangeMin, areaRangeMax,
		floorRangeMin, floorRangeMax,
		roomsNumberMax
	} from '../state'

  export default {
    components: { PluginRadioGroup, PluginRange },

    setup() {
      return {
				roomsNumberFilter, priceMin, priceMax, areaMin, areaMax, floorMin, floorMax,
				fetchFlatList, flatList, currentPage,
				priceRangeMin, priceRangeMax,
				areaRangeMin, areaRangeMax,
				floorRangeMin, floorRangeMax,
				roomsNumberMax
			}
    },

    data() { return {
      items: [
        { id: 1, text: '1', value: 1 },
        { id: 2, text: '2', value: 2 },
        { id: 2, text: '3', value: 3 }
      ],

      roomsNumber: null,
			priceRange: [ this.priceMin, this.priceMax ],
			areaRange: [ this.areaMin, this.areaMax ],
			floorRange: [ this.floorMin, this.floorMax ],
    } },

		props: {
			isOffice: {
				type: Boolean,
				default: false
			}
		},

    methods: {
      onFetchButtonClick() {
        this.currentPage = 0
        this.flatList = []
        
        this.setFlatListFilter()
        this.fetchFlatList( this.isOffice )
      },

      setFlatListFilter() {
        this.roomsNumberFilter = this.roomsNumber

				this.priceMin = this.priceRange[ 0 ]
				this.priceMax = this.priceRange[ 1 ]
				
				this.areaMin = this.areaRange[ 0 ]
				this.areaMax = this.areaRange[ 1 ]
				
				this.floorMin = this.floorRange[ 0 ]
				this.floorMax = this.floorRange[ 1 ]
      }
    },

		mounted() {
			this.items = []
			for (let i = 1; i <= this.roomsNumberMax; i++) {
				this.items.push( { id: i, text: i, value: i } )
			}
		}
  }
</script>