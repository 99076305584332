<template>
  <div>
    <flat-filter
      :isOffice="true"
    ></flat-filter>
  
    <flat-list
      :isOffice="true"
    ></flat-list>
  </div>
</template>

<script>
  import FlatFilter from '@/modules/flat/components/FlatFilter'
  import FlatList from '@/modules/flat/components/FlatList'

  export default {
    components: { FlatFilter, FlatList }
  }
</script>